<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="TBM 기본정보" class="cardClassDetailInfo">
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn
                  v-show="disabled"
                  label="출력"
                  icon="print"
                  @btnClicked="printTbm"
                />
                <!-- <c-btn
                  v-show="editable && tabParam.tbmId && !disabled"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="tabParam"
                  mappingType="PUT"
                  label="LBLCOMPLETE"
                  icon="check"
                  @beforeAction="completeTbm"
                  @btnCallback="completeCallback"
                />
                <c-btn
                  v-show="editable && tabParam.tbmId && !disabled"
                  label="LBLREMOVE"
                  :editable="editable"
                  icon="delete_forever"
                  @btnClicked="remove"
                /> -->
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="TBM명" :value="tabParam.tbmName"></c-label-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="LBLPROCESS" :value="tabParam.processName"></c-label-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="LBLJOB" :value="tabParam.sopName"></c-label-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="작업일" :value="tabParam.tbmWorkDate"></c-label-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <c-table
            ref="attendeeTable"
            title="참석자 및 건강상태 목록"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="tabParam.tbmAttendeeModelList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="참석자가 없습니다."
            :hideBottom="true"
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="tbmAttendeeId"
            
          >
            <template slot="table-button">
              <q-btn-group outline>
                <!-- <c-btn
                  v-if="isWorkPermit"
                  :showLoading="false"
                  label="참석자 불러오기"
                  icon="save_alt"
                  @btnClicked="workPermitLoadAttendee"
                /> -->
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="LBLSELECT"
                  icon="add"
                  @btnClicked="addAttendee1"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="LBLADDDIRECTLY"
                  icon="add"
                  @btnClicked="addAttendee2"
                />
                <!-- <c-btn
                  v-if="editable && tabParam.tbmId && !disabled"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="tabParam"
                  mappingType="PUT"
                  label="LBLCOMPLETE"
                  icon="save"
                  @beforeAction="completeTbm"
                  @btnCallback="completeCallback"
                /> -->
                <c-btn
                  v-if="
                    editable &&
                    !disabled &&
                    tabParam.tbmAttendeeModelList.length > 0
                  "
                  :showLoading="false"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="removeAttendee"
                />
                <c-btn
                  v-if="editable && !disabled && tabParam.tbmAttendeeModelList.length > 0"
                  :url="attendInserUrl"
                  :isSubmit="isSave"
                  :param="tabParam"
                  mappingType="POST"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveTbmAttendee"
                  @btnCallback="saveCallback"
                />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name ==='tbmAttendeeUserName'">
                <c-text-column
                  v-if="props.row['tbmDirectFlag'] === 'Y'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeUserName']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeUserName']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmAttendeeJobName'">
                <c-text-column
                  v-if="props.row['tbmDirectFlag'] === 'Y'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeJobName']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeJobName']}}
                </span>
              </template>
              <template v-if="col.name === 'click'">
                <q-btn 
                  round unelevated 
                  size="10px"
                  color="amber" 
                  icon="mode"
                  @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
                  >
                  <q-popup-proxy :ref="'proxy_' + props.rowIndex" :breakpoint="400">
                    <component
                      :is="col.component"
                      :outFlag="true"
                      :popupParam="props.row"
                      :data="tabParam"
                      :rowIndex="props.rowIndex"
                      @callback="callback"
                    />
                  </q-popup-proxy>
                </q-btn>
              </template>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from "quasar";
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: 'tbm-worker-health-status',
  props: {
    tabParam: {
      type: Object,
      default(){
        return {
          tbmCompleteFlag: 'N',
          sopWorkPermitId: "",
          vendorCd: '',
          tbmAttendeeModelList: [],
          deleteTbmAttendeeModelList: [],
          tbmId: "",
        }
      },
    },
  },
  data() {
    return {
      getUrl: '',
      grid: {
        columns: [],
        height: '650px',
        data: [],
      },
      saveType: "POST",
      attendInserUrl: "transactionConfig.sai.tbm.insert.attendee.url",
      completeUrl: "transactionConfig.sai.tbm.complete.url",
      attendDeleteUrl: "transactionConfig.sai.tbm.delete.attendee.url",
      isComplete: false,
      isSave: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        // isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: false,
      getWorkPermitUrl: '',
      deleteUrl: '',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
    disabled() {
      return this.tabParam.tbmCompleteFlag === 'Y'
    },
    isWorkPermit() {
      return (
        Boolean(this.tabParam.sopWorkPermitId) &&
        this.editable &&
        this.tabParam.tbmCompleteFlag === "N"
      );
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.completeUrl = transactionConfig.sai.tbm.complete.url;
      this.attendInserUrl = transactionConfig.sai.tbm.insert.attendee.url;
      this.attendDeleteUrl = transactionConfig.sai.tbm.delete.attendee.url;
      this.getWorkPermitUrl = selectConfig.sop.swp.workPermit.get.url;
      this.completeUrl = transactionConfig.sai.tbm.complete.url;
      this.deleteUrl = transactionConfig.sai.tbm.delete.url;
      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('TBM_HEALTH_STATUS_CD').then(_item => {
        this.grid.columns = [
          {
            name: "tbmAttendeeUserName",
            field: "tbmAttendeeUserName",
            label: "참석자",
            required: true,
            style: 'width:200px',
            type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            name: "tbmAttendeeJobName",
            field: "tbmAttendeeJobName",
            label: "LBLPOSITION",
            style: 'width:200px',
            type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            label: 'LBLITEMS',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'mentalFatigueFlag',
                field: 'mentalFatigueFlag',
                label: '정신적피로',
                style: 'width:100px', 
                align: 'center',
                type: 'check',
                true: 'Y',
                false: 'N',
                sortable: false,
              },
              {
                name: 'mentalPhychologicalFlag',
                field: 'mentalPhychologicalFlag',
                label: '심리적피로',
                style: 'width:100px', 
                align: 'center',
                type: 'check',
                true: 'Y',
                false: 'N',
                sortable: false,
              },
              {
                name: 'mentalPhysicalFlag',
                field: 'mentalPhysicalFlag',
                label: '육체적피로',
                style: 'width:100px', 
                align: 'center',
                type: 'check',
                true: 'Y',
                false: 'N',
                sortable: false,
              },
              {
                name: 'physicalDefectFlag',
                field: 'physicalDefectFlag',
                label: '신체적결함',
                style: 'width:100px', 
                align: 'center',
                type: 'check',
                true: 'Y',
                false: 'N',
                sortable: false,
              },
            ]
          },
          {
            name: 'healthStatusCd',
            field: 'healthStatusCd',
            label: '건강상태',
            type: 'select',
            setHeader: true,
            style: 'width:150px',
            align: 'center',
            comboItems: _item,
            sortable: false,
          },
          {
            name: 'click',
            field: 'click',
            label: '서명<br>',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
            component: () => import(`${'./signaturePop.vue'}`)
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            type: 'text',
            style: 'width:800px',
            align: 'left',
            sortable: false,
          },
        ]
      });
    },
    addAttendee1() {
      // 직영일때
      this.popupOptions.title = "TBM 참석자 검색"; // TBM 참석자 검색
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.tabParam.plantCd,
        vendorCd: this.tabParam.vendorCd,
        isUserAll: this.tabParam.vendorCd === 'VEN0000000' ? true : false,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.tbmAttendeeModelList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.tabParam.tbmAttendeeModelList.push({
              tbmId: this.tabParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeUserId: _item.userId,
              tbmAttendeeUserName: _item.userName,
              tbmAttendeeJobName: _item.jobName,
              healthStatusCd: "THS0000001",
              mentalFatigueFlag: "N",
              mentalPhychologicalFlag: "N",
              mentalPhysicalFlag: "N",
              physicalDefectFlag: "N",
              tbmDirectFlag: "N",
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    addAttendee2() {
      // 참석자 직접추가
      this.tabParam.tbmAttendeeModelList.push({
        tbmId: this.tabParam.tbmId,
        tbmAttendeeId: uid(),
        tbmAttendeeUserId: "",
        tbmAttendeeUserName: "",
        tbmAttendeeJobName: "",
        healthStatusCd: "THS0000001",
        mentalFatigueFlag: "N",
        mentalPhychologicalFlag: "N",
        mentalPhysicalFlag: "N",
        physicalDefectFlag: "N",
        tbmDirectFlag: "Y",
        remark: "",
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeAttendee() {
      let selectData = this.$refs["attendeeTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
          window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.attendDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.tabParam.tbmAttendeeModelList = this.$_.reject(this.tabParam.tbmAttendeeModelList, { tbmAttendeeId: item.tbmAttendeeId })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveTbmAttendee() {
      this.saveUrl = this.attendInserUrl;
      this.saveType = "POST";
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          let selectData = this.tabParam.tbmAttendeeModelList;
          let isConti = true;
          let checkItem = ['tbmAttendeeUserName']
          this.$_.forEach(selectData, item => {
            this.$_.forEach(checkItem, check => {
              if (!item[check]) {
                isConti = false;
                return false;
              }
            })
          }); 
          if (!isConti) {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: '필수 입력값을 입력해 주세요. [참석자]',
              type: 'warning', // success / info / warning / error
            });
          } else {
             window.getApp.$emit("CONFIRM", {
              title: "LBLCONFIRM",
              message: "MSGSAVE",
              // TODO : 필요시 추가하세요.
              type: "info", // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.tabParam.regUserId = this.$store.getters.user.userId;
                this.tabParam.chgUserId = this.$store.getters.user.userId;
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {},
            });
          }
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      if (!this.tabParam.tbmId) {
        this.$emit("updateMode", _result.data);
      } else {
        this.$emit("getDetail")
      }
    },
    completeTbm() {
      this.saveUrl = this.completeUrl;
      this.saveType = "PUT";
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          let selectData = this.tabParam.tbmAttendeeModelList;
          let isConti = true;
          let checkItem = ['tbmAttendeeUserName']
          this.$_.forEach(selectData, item => {
            this.$_.forEach(checkItem, check => {
              if (!item[check]) {
                isConti = false;
                return false;
              }
            })
          }); 
          if (!isConti) {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: '필수 입력값을 입력해 주세요. [참석자]',
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit("CONFIRM", {
              title: "LBLCONFIRM",
              message: "TBM을 완료하시겠습니까? 완료할 시 탭[ TBM 기본정보, 참석자 및 건강상태, 관련 기계 및 장비 ]을 수정 할 수 없습니다.",
              // TODO : 필요시 추가하세요.
              type: "info", // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.tabParam.regUserId = this.$store.getters.user.userId;
                this.tabParam.chgUserId = this.$store.getters.user.userId;
                this.isComplete = !this.isComplete;
              },
              // 취소 callback 함수
              cancelCallback: () => {},
            });
          }
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    workPermitLoadAttendee() {
      this.$http.url = this.$format(this.getWorkPermitUrl, this.tabParam.sopWorkPermitId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data.workers && _result.data.workers.length > 0) {
          this.$_.forEach(_result.data.workers, _item => {
            let index = this.$_.findIndex(this.tabParam.tbmAttendeeModelList, {
              tbmAttendeeUserName: _item.workerName, 
              tbmAttendeeJobName: _item.jobName,
            });
            if (index === -1) {
              this.tabParam.tbmAttendeeModelList.push({
                tbmId: this.tabParam.tbmId,
                tbmAttendeeId: uid(),
                tbmAttendeeUserId: _item.userId,
                tbmAttendeeUserName: _item.workerName,
                tbmAttendeeJobName: _item.jobName,
                healthStatusCd: "THS0000001",
                mentalFatigueFlag: "N",
                mentalPhychologicalFlag: "N",
                mentalPhysicalFlag: "N",
                physicalDefectFlag: "N",
                tbmWorkPermitFlag: "Y",
                regUserId: this.$store.getters.user.userId,
                editFlag: "C",
              })
            }
          })
        } 
      });
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'TBM을 삭제하시겠습니까? 삭제할 시 모든 정보가 삭제됩니다.',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tabParam.tbmId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.$emit("getDetail");
    },
    printTbm() {
      let thisVue = this;
      this.$http.url = this.$format(selectConfig.sai.tbm.print.url, this.tabParam.tbmId);
      this.$http.type = "GET";
      this.$http.request(
        (_result) => {
          let fileOrgNm = this.tabParam.tbmNo + ".xlsx";
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        },
        () => {}
      );
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    callback(data, rowIndex) {
      this.tabParam.tbmAttendeeModelList[rowIndex].electronSignature = data
      this.tabParam.tbmAttendeeModelList[rowIndex].eduAttendFlag = 'Y'
      if(this.tabParam.tbmAttendeeModelList[rowIndex].editFlag !== 'C') {
        this.tabParam.tbmAttendeeModelList[rowIndex].editFlag = 'U'
      }
      let refName = 'proxy_' + rowIndex;
      this.$refs[refName].hide();
    },
  }
};
</script>